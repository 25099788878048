import React from "react"
import { SiteFooter } from "../SiteFooter"
import { SiteHeader } from "../SiteHeader"

const Layout = ({ children }) => {
  return (
    <>
      <div className="bg-content">
        <SiteHeader />
        {children}
        <SiteFooter />
      </div>
    </>
  )
}

export { Layout }
