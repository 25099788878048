import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { mediaQueries } from "../config/mediaQueries"
import { SEO as Seo } from "../components/seo"
import { Layout } from "../components/layout"

const ListTitleH2 = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #696969;
`

const Container = styled.div`
  padding: 1rem;
  margin: 0 auto;
  ${mediaQueries("sm")`
    width: 670px;
    padding: 2rem 0;
  `}
  .blog-post__content > p,
  .blog-post__content > ul {
    line-height: 1.8;
  }
  & + & {
    padding-top: 0;
  }
`

const Tag = ({ pageContext, data }) => {
  const posts = data.allMarkdownRemark.edges
  const { blogContextPath, tag } = pageContext
  return (
    <Layout>
      <Seo title={`Tag: ${tag}`} description={`all posts under tag "${tag}"`} />
      <Container>
        <ListTitleH2>Tag: {tag}</ListTitleH2>
        <ul>
          {posts.map(post => (
            <li key={post.node.id}>
              <Link to={`${blogContextPath}${post.node.fields.slug}`}>
                <h5>{post.node.frontmatter.title}</h5>
              </Link>
              <small className="d-block text-muted">
                Posted on {post.node.frontmatter.date}
              </small>
            </li>
          ))}
        </ul>
      </Container>
    </Layout>
  )
}

Tag.propTypes = {
  data: PropTypes.shape({}),
  pageContext: PropTypes.shape({}),
}

export default Tag

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 100
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM, YYYY")
            tags
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
